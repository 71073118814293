import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'




const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq">

      <Container>

      <div className="text-center">
          
          <h4 className="heading-h2"><span className="heading-h3 bluecolor d-none d-sm-block">FAQ</span>
            Frequently Asked Questions</h4>
        </div>
            

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                1. What is Coinbase Clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Coinbase Clone script is a readymade customizable cryptocurrency exchange software that helps budding entrepreneurs build a platform similar to Coinbase
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                2. Why should I use a Coinbase clone script for creating a crypto exchange?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Building an exchange like coinbase from scratch might take more time and it’s more expensive so you can use coinbase clone software which is cost-effective. Our Coinbase Clone Script is the pre-designed exchange script and you can build a feature-rich crypto exchange platform like Coinbase within a few days.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                3. What security features does the Coinbase clone have?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Our coinbase clone software has all the tightened security features like Cross-site Request Forgery (CSRF) protection, SSL encryption, Two-factor authentication, Anti-phishing software, Lock registry, Data encryption, and more.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                4. How much does it cost to launch a bitcoin exchange like coinbase?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">We can not predict the exact cost of coinbase clone software development. Because it varies based on your business requirements and the trading features you are going to enable in the trading platform.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                5. What are the advantages of using a coinbase app clone software?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">You can yield several benefits by getting a bug-free coinbase app clone script from the coinsclone. Such as Customization, Saves time, faster deployment, Cost-effective, error-free, secure, and more.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                6. Where can I get the best Coinbase website clone script for starting a crypto exchange business?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">You can get an advanced and the best Coinbase clone script from the Coinsclone with the latest trading features and you get a free demo of our Coinbase Clone Script by approaching our team experts.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection