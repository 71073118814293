import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose</span> Coinsclone For Coinbase Clone Script?</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <img width="522px" height="508px" src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/why-choose-coinsclone-for-coinbase-clone-script.png" alt="coinbase clone script software provider" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Coinsclone is a top-notch <span className='learn-more'><a href='https://www.coinsclone.com/cryptocurrency-exchange-development-company/'>cryptocurrency exchange development company</a></span> in the crypto sector. We excel in offering a complete package of crypto exchange clone script software solutions for all popular exchanges. So far we have helped several crypto startups and entrepreneurs who are thriving to initiate a flourishing user-to-admin cryptocurrency exchange like Coinbase. We have a team of well-experienced front-end & back-end developers, Quality analysts, Android & iOS developers for the coinbase clone app development, and project engineers to structure your crypto exchange project as per your business concepts.
              </p>
              <p className="pharagraph">We use the latest technology stacks for crafting our supreme Coinbase clone script software. Our script is completely designed, developed, tested, verified, and ready for deployment so it can be initiated instantly. We have a dedicated team of experts to offer productive 24/7 customer and technical support for all queries related to coinbase clone software assistance. 
              </p>
              <p className="pharagraph mb-0">Our primary motto is Customer satisfaction so we are satisfied only if our reputed international customers are satisfied with our product. Talk to our team experts and launch your own crypto exchange like Coinbase with Coinsclone and acquire great ROI.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose