import * as React from "react"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Banner from "../components/Coinbase/Banner"
import Whatis from "../components/Coinbase/Whatis"
//import QuickviewSection from "../components/Coinbase/Quickview"
import HowOur from "../components/Coinbase/HowOur"
import TopFeatures from "../components/Coinbase/TopFeatures"
import CoreFeatures from "../components/Coinbase/CoreFeatures"
import SecurityofOur from "../components/Coinbase/SecurityofOur"
import TechStack from "../components/Coinbase/TechStack"
import FaqSection from "../components/Coinbase/FaqSection"
import WhyShould from "../components/Coinbase/WhyShould"
import DevApproach from "../components/Coinbase/DevApproach"
import WhyChoose from "../components/Coinbase/WhyChoose"
import Addon from "../components/Coinbase/Addon"
import OurAndroid from "../components/Coinbase/OurAndroid"
import Testimonial from "../components/Clone/Testimonial"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"
import CaseStudy from "../components/Coinbase/CaseStudy"





const Coinbase = () => (
  <section className="landing clone">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>Coinbase Clone Script to create user-to-admin exchange</title>
        <meta name="description" content="Coinbase Clone Script is a customizable user-to-admin crypto exchange software to start building your platform like Coinbase Instantly with similar features" />
        <meta name="keywords" content="Coinbase Clone Script" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Coinbase Clone Script to create user-to-admin exchange" />
        <meta property="og:description" content="Coinbase Clone Script is a customizable user-to-admin crypto exchange software to start building your platform like Coinbase Instantly with similar features" />
        <meta property="og:url" content="https://www.coinsclone.com/coinbase-clone-script/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/coinbase-clone-script-software.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinbase Clone Script is a customizable user-to-admin crypto exchange software to start building your platform like Coinbase Instantly with similar features" />
        <meta name="twitter:title" content="Coinbase Clone Script to create user-to-admin exchange" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/coinbase-clone-script-software.png" />
        <link rel="canonical" href="https://www.coinsclone.com/coinbase-clone-script/" />
      </Helmet>
      <Banner />
      <div className="breadcrumb mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Coinbase Clone Script </span>
        </div>
      </div>
      <Whatis />
      <CaseStudy />
      {/* <QuickviewSection /> */}
      <HowOur />
      <WhyShould />
      <TopFeatures />
      <CoreFeatures />
      <SecurityofOur />
      <Addon />
      <OurAndroid />
      <WhyChoose />
      <DevApproach />
      <Testimonial />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default Coinbase
