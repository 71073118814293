import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from "gatsby-plugin-image"

class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Core Features</span> of </span>
              Our Coinbase Clone Script</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  User Panel
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Powerful Admin Portal
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Convert Crypto
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  KYC System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Different Payment Modes
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Instant Notifications
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Admin Profit Management
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Device Management
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Support System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Content Management System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab11')} >
                  IP Restriction
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab12')} >
                  Ban Users
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">User Panel
                        </h4>
                        <p className="pharagraph">We provide an exceptional user panel with a simple and easy interface which makes even the beginners to buy/sell cryptocurrencies effortlessly.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/userpanel.png"
                      alt="User Panel"
                      width={430}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Powerful Admin Portal
                        </h4>
                        <p className="pharagraph">Supervise each and every action of your users under one place using the significant admin panel we provide in our Coinbase website script.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/powerful-admin-portal.png"
                      alt="Powerful Admin Portal"
                      width={470}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Convert Crypto
                        </h4>
                        <p className="pharagraph">Convert your digital assets in seconds without worrying about complicated methods such as order book and trading types.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase/convert-crypto.png"
                      alt="Convert Crypto"
                      width={470}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">KYC System
                        </h4>
                        <p className="pharagraph">Our Coinbase app script comes with a pre configured KYC system which allows only authentic users to exchange the cryptocurrencies.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/kyc-system.png"
                      alt="KYC System"
                      width={320}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Different Payment Modes
                        </h4>
                        <p className="pharagraph">Buy with Credit card, Debit Card, Bank-transfer using our Coinbase app clone script.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/different-payment-modes.png"
                      alt="Different Payment Modes"
                      width={320}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Instant Notifications
                        </h4>
                        <p className="pharagraph">Get swift notifications for every deposit, withdrawal, buy/sell, market trends and other platform related announcements.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/instant-notifications.png"
                      alt="Instant Notifications"
                      width={320}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Admin Profit Management
                        </h4>
                        <p className="pharagraph">Determine the profit percentage of your own by setting the exchange & withdrawal fee using the admin profit management we provide in our clone script.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/admin-profit-management.png"
                      alt="Admin Profit Management"
                      width={470}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Device Management
                        </h4>
                        <p className="pharagraph">Our Coinbase clone script provides the facility for the users to monitor the recent login sessions and stay aware of them.
                        </p>
                        <div className="mw350 text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/device-management.png"
                      alt="Device Management"
                      width={350}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Support System
                        </h4>
                        <p className="pharagraph">Get resolved with your queries and issues quickly by approaching the admin through the support system in our Coinbase exchange clone script.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/support-system.png"
                      alt="Support System"
                      width={317}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Content Management System
                        </h4>
                        <p className="pharagraph">Add/remove/edit the site contents with all the available formating tools available in our Coinbase app clone script.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/content-management-system.png"
                      alt="Content Management System"
                      width={350}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab11" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">IP Restriction
                        </h4>
                        <p className="pharagraph">Admin can limit or give access to specific IP’s in which the system can be accessed which helps the platform to stay away from suspicious activities or any attacks.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/ip-restriction.png"
                      alt="IP Restriction"
                      width={278}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab12" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Ban Users
                        </h4>
                        <p className="pharagraph">Suspend a specific user from the admin panel if he/she is suspected as a threat to the exchange.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/ban-users.png"
                      alt="Ban Users"
                      width={320}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures