import React from 'react'


const SecurityofOur = () => {

  return (
    <section className="security pt-100 mb-0 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
          <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Security Features</span> of </span>
            Our Coinbase Clone Software</h3>
            <p className="pharagraph head">We enable advanced security features in our exclusive coinbase website script to eliminate fraudulent activities.</p>
          </div>
        </div>
        <div className="binanceflexbox mt-0 version2" >
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="33.864" height="33.86" viewBox="0 0 33.864 33.86">
                  <g id="Private_key_cryptography" data-name="Private key cryptography" transform="translate(-0.001 -0.003)">
                    <path id="Path_9273" data-name="Path 9273" d="M64,171.091a1.256,1.256,0,0,1,1.3-.736q8.8.022,17.593.009h.4v4.63H69.058a.991.991,0,0,0-.951,1.49,1.012,1.012,0,0,0,.945.5H83.31a3.812,3.812,0,0,0,.6,2.646H69.179a2.179,2.179,0,0,0-.4.017.991.991,0,0,0,.12,1.964c.1.007.2,0,.3,0H85.9a1.114,1.114,0,0,0,1.2-.678.3.3,0,0,1,.232-.115c1.245-.007,2.49-.005,3.786-.005v8.859c0,.822-.37,1.2-1.185,1.2H69.726a.768.768,0,0,0-.6.249q-1.531,1.555-3.087,3.087c-.239.235-.519.427-.781.639h-.529A1.5,1.5,0,0,1,64,194.11Zm13.523,15.147h8.591a.989.989,0,0,0,.253-1.955,1.965,1.965,0,0,0-.427-.026H68.987a.993.993,0,1,0,.075,1.982q4.229,0,8.46,0Z" transform="translate(-63.999 -160.979)" fill="gray" />
                    <path id="Path_9274" data-name="Path 9274" d="M64,67.211a7.779,7.779,0,0,1,.484-1.319,3.621,3.621,0,0,1,6.541.491.4.4,0,0,0,.447.305c3.527-.009,7.053,0,10.58-.009a1.029,1.029,0,0,1,1.029.574.983.983,0,0,1-.851,1.408c-.328.017-.658,0-1.034,0,0,.588.012,1.157,0,1.725a.981.981,0,0,1-1.405.889.966.966,0,0,1-.574-.928c0-.549,0-1.1,0-1.665H77.889v.7c0,.8-.358,1.257-.984,1.263s-1-.458-1-1.246v-.712c-.1-.009-.183-.024-.267-.024H71.406a.312.312,0,0,0-.354.242,3.628,3.628,0,0,1-7-.65A.545.545,0,0,0,64,68.137Z" transform="translate(-63.999 -64.044)" fill="#b0b0b0" />
                    <path id="Path_9275" data-name="Path 9275" d="M314.713,71.679a26.677,26.677,0,0,0-.023-3.116,2.891,2.891,0,0,0-3.14-2.5,2.99,2.99,0,0,0-2.744,2.889.995.995,0,1,1-1.986.03,4.961,4.961,0,1,1,9.921.147c0,.836,0,1.672,0,2.5.039.025.056.045.074.046.971.054,1.249.351,1.249,1.338v7.738c0,.815-.374,1.185-1.2,1.185h-10.18c-.816,0-1.186-.373-1.186-1.194V72.866c0-.817.372-1.187,1.192-1.187h8.023ZM310.79,76.5c0,.55-.008,1.088,0,1.625a.99.99,0,1,0,1.979-.026c.009-.536,0-1.071,0-1.6.072-.024.1-.036.133-.043a.988.988,0,0,0-.2-1.964q-.941-.011-1.882,0a.98.98,0,0,0-1,.751C309.674,75.822,310.033,76.31,310.79,76.5Z" transform="translate(-284.199 -64.08)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Private Key Cryptography</h5> </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="33.881" height="30.677" viewBox="0 0 33.881 30.677">
                  <g id="Cross-site_Request_Forgery_CSRF_protection" data-name="Cross-site Request Forgery CSRF protection" transform="translate(0 0.013)">
                    <path id="Path_9280" data-name="Path 9280" d="M288.27,287.882a2.3,2.3,0,0,1-.661,1.082,2.012,2.012,0,0,1-1.324.424h-11.9a1.83,1.83,0,0,1-1.991-2V277.272a1.811,1.811,0,0,1,1.96-1.957c3.868,0,7.736.018,11.6-.013a2.083,2.083,0,0,1,2.313,1.469Zm-8.884-3.429c0,.231.009.463,0,.694-.051,1.087.192,1.165,1.177,1.136.493-.014.7-.222.708-.712.005-.749.008-1.5,0-2.247a.416.416,0,0,1,.237-.415,2.274,2.274,0,1,0-2.374-.016.455.455,0,0,1,.26.469c-.018.363-.007.727-.007,1.091Z" transform="translate(-254.389 -258.732)" fill="#b0b0b0" />
                    <path id="Path_9281" data-name="Path 9281" d="M37.338,120.057v3.376c-.47-.215-.913-.458-1.384-.626a6.584,6.584,0,0,0-8.525,4.121,6.874,6.874,0,0,0-.328,2.377c0,.172-.022.273-.222.329a2.8,2.8,0,0,0-2.18,2.521,1.837,1.837,0,0,1-.048.187H9.815a1.835,1.835,0,0,1-2.032-2.02q0-4.958,0-9.916v-.349Zm-24.5,10.437h2.281a.9.9,0,0,0,0-1.8q-2.265-.009-4.53,0a.9.9,0,1,0,0,1.8c.752.006,1.5,0,2.251,0Z" transform="translate(-7.759 -114.036)" fill="gray" />
                    <path id="Path_9282" data-name="Path 9282" d="M37,34.42H7.443a11.754,11.754,0,0,1,.1-1.578,1.766,1.766,0,0,1,1.781-1.387H34.969a1.818,1.818,0,0,1,2.014,1.43A9,9,0,0,1,37,34.42Z" transform="translate(-7.443 -31.453)" fill="gray" />
                    <path id="Path_9283" data-name="Path 9283" d="M318.569,175.489h-1.9a3.758,3.758,0,0,0-1.366-3.378,3.524,3.524,0,0,0-2.33-.788,3.572,3.572,0,0,0-2.734,1.26,3.873,3.873,0,0,0-.875,2.907h-1.889a5.409,5.409,0,0,1,2.506-5.152,5.315,5.315,0,0,1,5.991-.056A5.438,5.438,0,0,1,318.569,175.489Z" transform="translate(-287.077 -160.013)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Cross-Site Request Forgery
                  (CSRF) Protection</h5>
              </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="SSL_encryption" data-name="SSL encryption" xmlns="http://www.w3.org/2000/svg" width="37.377" height="30.898" viewBox="0 0 37.377 30.898">
                  <path id="Path_8921" data-name="Path 8921" d="M741.377,467.563v19.273a2.183,2.183,0,0,1-2.385,1.464q-16.394-.033-32.789-.013c-.134,0-.268,0-.4,0a1.821,1.821,0,0,1-1.516-.871,5.979,5.979,0,0,1-.286-.577V467.563c.121,0,.243.009.364.009h36.649C741.134,467.572,741.256,467.566,741.377,467.563Zm-18.719,16.708h2.881a1.862,1.862,0,0,0,1.89-1.827q.022-2.5,0-5a1.529,1.529,0,0,0-.875-1.492.868.868,0,0,1-.58-1.085.762.762,0,0,0,0-.109,3.284,3.284,0,0,0-5.869-1.9,3.593,3.593,0,0,0-.7,2.5c.008.166.024.306-.205.4a1.815,1.815,0,0,0-1.253,1.854q0,2.316,0,4.632a1.88,1.88,0,0,0,2.018,2.032C720.859,484.275,721.759,484.271,722.659,484.271Z" transform="translate(-704 -457.402)" fill="gray" />
                  <path id="Path_8922" data-name="Path 8922" d="M741.377,329.853v6.5c-.121,0-.243.009-.364.009H704.364c-.121,0-.243-.006-.364-.009v-6.5a2.133,2.133,0,0,1,2.339-1.473q16.423.034,32.845.013c.134,0,.268-.006.4,0a1.815,1.815,0,0,1,1.647,1.133C741.282,329.636,741.329,329.745,741.377,329.853ZM732,331.289h-2.115c-1.094,0-2.188-.009-3.282.008a1.08,1.08,0,0,0-.36,2.091,2.007,2.007,0,0,0,.644.087q5.106.008,10.212,0a2.319,2.319,0,0,0,.47-.039,1.024,1.024,0,0,0,.812-.751,1.08,1.08,0,0,0-1.129-1.4C735.5,331.284,733.753,331.289,732,331.289Zm-22.016,1.11a1.564,1.564,0,1,0-1.564,1.542A1.57,1.57,0,0,0,709.986,332.4Zm4.536-.015a1.559,1.559,0,0,0-1.569-1.572,1.564,1.564,0,1,0,.025,3.129A1.553,1.553,0,0,0,714.522,332.384Z" transform="translate(-704 -328.38)" fill="gray" />
                  <path id="Path_8923" data-name="Path 8923" d="M925.413,608.5h5.058v4.206h-5.058Z" transform="translate(-909.249 -588.051)" fill="#b0b0b0" />
                  <path id="Path_8924" data-name="Path 8924" d="M947.157,554.589h-2.178a8.254,8.254,0,0,1,.006-.913,1.092,1.092,0,0,1,2.168.053C947.176,554,947.157,554.282,947.157,554.589Z" transform="translate(-927.377 -536.361)" fill="#b0b0b0" />
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">SSL Encryption</h5>
              </div></div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="31.985" height="34.515" viewBox="0 0 31.985 34.515">
                  <g id="_2FA" data-name="2FA" transform="translate(0)">
                    <path id="Path_5209" data-name="Path 5209" d="M33.332,0c.559.088,1.132.148,1.685.263A13.762,13.762,0,0,1,45.9,11.552a8.973,8.973,0,0,1,.135,1.274c.02,1.112.007,2.224.007,3.377a10.665,10.665,0,0,0-7.535-.054V13.877a6.564,6.564,0,0,0-.66-2.925A6.1,6.1,0,0,0,26.511,12.1a7.374,7.374,0,0,0-.229,1.624c-.034,1.334-.013,2.676-.007,4.01,0,.067.013.128.02.209h2v-.4c.007-1.4-.034-2.79.04-4.179a4.063,4.063,0,0,1,8.1-.007c.067,1.206.034,2.426.034,3.639a.479.479,0,0,1-.168.3c-.674.607-1.348,1.213-2.035,1.806a.516.516,0,0,1-.377.115.93.93,0,0,1-.775-1.024c-.007-1.6,0-3.215,0-4.819v-.391H31.128a.579.579,0,0,0-.027.142c0,1.766-.02,3.525.013,5.291a2.841,2.841,0,0,0,1.624,2.534.609.609,0,0,1,.108.067c-.377,1.126-.741,2.224-1.126,3.37a3.91,3.91,0,0,1-2.4-1.348,4.006,4.006,0,0,1-1.011-2.655H26.3a4.977,4.977,0,0,0,.27,1.813,6.008,6.008,0,0,0,4.83,4.188c.256.047.3.148.337.377.135.829.31,1.651.479,2.473a4.109,4.109,0,0,0,.135.411,7.878,7.878,0,0,1-2.709-.438,8.687,8.687,0,0,1-5.553-5.553,8.516,8.516,0,0,1-.452-2.878c0-2.379-.007-4.765,0-7.144a8.759,8.759,0,1,1,17.514-.383c0,.067.006.128.012.209h2.022c-.034-.452-.04-.91-.1-1.355A10.63,10.63,0,0,0,39.33,5.237a10.382,10.382,0,0,0-5.291-2.406,10.787,10.787,0,0,0-12.151,8.236,12.645,12.645,0,0,0-.283,2.568c-.034,2.379-.02,4.765-.013,7.144a10.605,10.605,0,0,0,3.9,8.418,10.413,10.413,0,0,0,7.05,2.487,3.14,3.14,0,0,0,.438-.013.545.545,0,0,1,.553.263c.559.674,1.152,1.314,1.732,1.968a1.5,1.5,0,0,0,.222.189c-.013.034-.02.061-.034.094l-1.779.263c-.121.02-.236.047-.357.067H31.431a1.763,1.763,0,0,0-.256-.061,13.164,13.164,0,0,1-7.5-3.093A13.509,13.509,0,0,1,18.707,20.67c-.007-2.291-.013-4.583.007-6.874a15.109,15.109,0,0,1,.162-2.211,13.4,13.4,0,0,1,3.6-7.305A13.562,13.562,0,0,1,29.76.263C30.319.148,30.885.088,31.445,0Z" transform="translate(-18.7)" fill="gray" />
                    <path id="Path_5210" data-name="Path 5210" d="M249.319,276.373c-.505-.088-1.024-.128-1.523-.263a8.311,8.311,0,0,1-6.288-6.308,8.419,8.419,0,0,1,3.727-9.1,8.209,8.209,0,0,1,9.186.094,8.072,8.072,0,0,1,3.727,5.789,8.465,8.465,0,0,1-5,9.065,8.2,8.2,0,0,1-2.628.667,2.806,2.806,0,0,0-.33.054Zm3.72-10.554a3.615,3.615,0,0,0-.762-2.662,3.2,3.2,0,0,0-2.5-1.186,3.093,3.093,0,0,0-2.049.728,3.464,3.464,0,0,0-1.24,3.12c-.216.013-.391.027-.566.034v6.9h7.69v-6.908C253.437,265.846,253.275,265.833,253.039,265.819Z" transform="translate(-226.263 -241.867)" fill="#b0b0b0" />
                    <path id="Path_5211" data-name="Path 5211" d="M340.3,389.037V386.2h3.646v2.837Z" transform="translate(-318.625 -360.172)" fill="#b0b0b0" />
                    <path id="Path_5212" data-name="Path 5212" d="M351.182,330.168h-2.46a1.525,1.525,0,0,1,.5-1.577,1.187,1.187,0,0,1,1.361-.074C351.222,328.881,351.256,329.488,351.182,330.168Z" transform="translate(-326.454 -306.209)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Two-Factor
                  Authentication
                </h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="36.702" height="36.699" viewBox="0 0 36.702 36.699">
                  <g id="Anti-phishing_software" data-name="Anti-phishing software" transform="translate(0.004)">
                    <path id="Path_8930" data-name="Path 8930" d="M847.969,461.654c-.836-.4-1.7-.752-2.5-1.22a17.952,17.952,0,0,1-9.113-13.731,18.332,18.332,0,0,1-.134-2.286c-.018-2.042-.006-4.085-.006-6.128v-.456a13.913,13.913,0,0,0,11.824-4.746,14.482,14.482,0,0,0,5.351,3.812,15.232,15.232,0,0,0,6.478.942v6.648a18.223,18.223,0,0,1-11.47,17.024c-.1.04-.189.094-.284.142Zm-7.494-19.693c.043,1.418-.025,2.826.147,4.2a13.587,13.587,0,0,0,7.189,10.664.513.513,0,0,0,.444.008,24.383,24.383,0,0,0,2.711-1.916c3.868-3.477,4.972-7.958,4.543-12.968a1.512,1.512,0,0,0-.178-.052,18.082,18.082,0,0,1-7.092-2.914c-.185-.127-.292-.052-.441.033-.921.525-1.826,1.084-2.777,1.551A17.5,17.5,0,0,1,840.475,441.961Z" transform="translate(-829.692 -424.956)" fill="gray" />
                    <path id="Path_8931" data-name="Path 8931" d="M1014.27,399.763a2.87,2.87,0,0,0-.3.231q-1.537,1.529-3.066,3.066a2.357,2.357,0,0,0-.217.287l-1.537-1.539.967-.926-.045-.116h-.357q-3.279,0-6.558-.007a.551.551,0,0,1-.412-.169c-1.014-1.659-2.01-3.33-3.029-5.029l1.84-1.107.536.891c.608,1.013,1.212,2.029,1.832,3.034a.5.5,0,0,0,.343.226c1.9.014,3.8.009,5.7.008a.475.475,0,0,0,.1-.025l-.969-.929,1.573-1.561c.022.021.116.107.206.2l3.093,3.09a3.056,3.056,0,0,0,.3.233Z" transform="translate(-977.572 -390.015)" fill="gray" />
                    <path id="Path_8932" data-name="Path 8932" d="M768,399.614a2.339,2.339,0,0,0,.272-.208q1.546-1.569,3.086-3.143c.074-.076.154-.146.2-.193l1.517,1.514-1.077,1c.222.017.32.031.419.031q2.741,0,5.48.007a.477.477,0,0,0,.479-.269c.763-1.294,1.54-2.579,2.331-3.9l1.832,1.1c-.05.1-.089.177-.135.255-.938,1.566-1.88,3.128-2.81,4.7a.478.478,0,0,1-.478.269c-2.2-.01-4.393-.006-6.59-.006h-.456l1.05.994-1.562,1.547c-.048-.046-.141-.129-.229-.218l-3.086-3.144a1.883,1.883,0,0,0-.247-.182Z" transform="translate(-768 -390.009)" fill="gray" />
                    <path id="Path_8933" data-name="Path 8933" d="M914.68,348l1.807,1.124-1.506,2.506-1.841-1.1c.208-.347.411-.678.6-1.014.291-.5.575-1.008.863-1.512Z" transform="translate(-899.269 -348)" fill="gray" />
                    <path id="Path_8934" data-name="Path 8934" d="M974.094,348c.148.294.287.594.447.882.3.55.622,1.1.945,1.662l-1.865,1.065-1.445-2.535L973.95,348Z" transform="translate(-952.662 -348)" fill="gray" />
                    <path id="Path_8935" data-name="Path 8935" d="M914.013,523.193a11.879,11.879,0,0,1-5.124,10.632.369.369,0,0,1-.5.009,11.512,11.512,0,0,1-4.987-7.993c-.131-.857-.135-1.733-.2-2.617.383-.116.709-.214,1.035-.314a18.547,18.547,0,0,0,4.08-1.8.514.514,0,0,1,.611-.012,19.327,19.327,0,0,0,5.085,2.095Z" transform="translate(-890.282 -504.466)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Anti-Phishing Software</h5> </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="33.924" height="31.112" viewBox="0 0 33.924 31.112">
                  <g id="Lock_registry" data-name="Lock registry" transform="translate(-0.002 0)">
                    <path id="Path_9287" data-name="Path 9287" d="M85.918,71.5V91.971a6.1,6.1,0,0,1-.295.867,2.815,2.815,0,0,1-2.714,1.6q-4.654-.006-9.308,0H73.19a8.741,8.741,0,0,0,.574,3.019,1.814,1.814,0,0,0,1.171,1.175c.18.046.372.049.553.092A.665.665,0,0,1,76,99.5c-.064.412-.353.606-.917.606H62.827a1.811,1.811,0,0,1-.393-.042.678.678,0,0,1-.533-.7c.021-.4.294-.642.824-.687a1.417,1.417,0,0,0,1.059-.6,5.484,5.484,0,0,0,.871-2.739c.038-.289.042-.581.064-.9H55a2.836,2.836,0,0,1-3-3.015V72.012A2.84,2.84,0,0,1,54.972,69q13.979-.011,27.958,0a2.957,2.957,0,0,1,2.113.8,3.184,3.184,0,0,1,.875,1.7Zm-2.85.351H54.849V88.765H83.068Zm-12.7,19.764a1.409,1.409,0,1,0-1.394,1.42,1.409,1.409,0,0,0,1.394-1.42Z" transform="translate(-51.992 -68.994)" fill="gray" />
                    <path id="Path_9288" data-name="Path 9288" d="M198.484,125.222a1.45,1.45,0,0,1,1.393,1.63q0,2.98,0,5.96a1.442,1.442,0,0,1-1.609,1.618h-8.079a1.442,1.442,0,0,1-1.61-1.617q0-2.98,0-5.96a1.455,1.455,0,0,1,1.408-1.634,4.71,4.71,0,0,1,1.551-3.935,3.966,3.966,0,0,1,2.835-.989,4.349,4.349,0,0,1,4.111,4.927Zm-5.658,4.352a1.4,1.4,0,0,0,.479.948.846.846,0,0,1,.2.438c.042.259.011.53.053.789a.68.68,0,0,0,1.334,0,3.716,3.716,0,0,0,.039-.724.594.594,0,0,1,.231-.537,1.335,1.335,0,0,0,.312-1.654,1.415,1.415,0,0,0-1.6-.719A1.439,1.439,0,0,0,192.826,129.574Zm.014-4.354h2.777c.062-.728.069-1.424-.625-1.869a1.334,1.334,0,0,0-1.528,0c-.693.449-.686,1.141-.624,1.868Z" transform="translate(-177.265 -116.044)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Lock Registry</h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Secured_content_management_system" data-name="Secured content  management system" xmlns="http://www.w3.org/2000/svg" width="34.507" height="33.149" viewBox="0 0 34.507 33.149">
                  <path id="Path_5217" data-name="Path 5217" d="M0,119.619V104.05c5.156,0,10.312-.007,15.467.013a1.506,1.506,0,0,0,1.436-.782c.634-1.018,1.328-2,1.981-3.006A.536.536,0,0,1,19.4,100q7.36.01,14.726.007H34.5V119.12a3.026,3.026,0,0,1-3.12,3.127c-1.5.007-3.013,0-4.516,0H24.546c.047-.142.088-.256.115-.371a20.568,20.568,0,0,0,.593-2.891,47.093,47.093,0,0,0,.061-4.778,2.771,2.771,0,0,0-1.779-2.568,15.526,15.526,0,0,0-4.576-1.179,16.391,16.391,0,0,0-7.757,1.078,3.034,3.034,0,0,0-2.035,2.918c-.007,1.146-.054,2.291.013,3.437.061,1.011.229,2.022.384,3.033a11.627,11.627,0,0,0,.377,1.328H3.208c-.135,0-.27,0-.4-.007a3.044,3.044,0,0,1-2.682-2.17C.081,119.922.04,119.774,0,119.619Z" transform="translate(0 -93.945)" fill="gray" />
                  <path id="Path_5218" data-name="Path 5218" d="M0,12.78c.054-.182.094-.364.155-.546a3.022,3.022,0,0,1,2.891-2.076c2.291-.007,4.583,0,6.874,0H31.353a3,3,0,0,1,3.12,2.628,9.85,9.85,0,0,1,.013,1.415H34.1c-5.109,0-10.224.007-15.333-.007a1.292,1.292,0,0,0-1.2.64c-.654,1.018-1.348,2.015-2.008,3.033a.727.727,0,0,1-.694.384c-4.954-.013-9.907-.007-14.861-.007Q0,15.506,0,12.78Zm5.061,2.426a1.011,1.011,0,0,0,.007-2.022,1.011,1.011,0,1,0-.007,2.022Zm5.048-1a1.011,1.011,0,1,0-1.018.991A1.011,1.011,0,0,0,10.109,14.209Zm3.013-1.018a1.016,1.016,0,0,0-.991,1.018,1.011,1.011,0,1,0,.991-1.018Z" transform="translate(0 -10.156)" fill="gray" />
                  <path id="Path_5219" data-name="Path 5219" d="M177.949,288.281a12.527,12.527,0,0,1-.4,3.922,10.238,10.238,0,0,1-5.014,6.194,1.211,1.211,0,0,1-1.3,0,10.1,10.1,0,0,1-5.412-8.91c-.04-1.166-.007-2.339-.007-3.5a1.034,1.034,0,0,1,.728-1.058,14.189,14.189,0,0,1,10.608-.02,1.045,1.045,0,0,1,.8,1.139Zm-6.807,3.067c-.256-.283-.465-.553-.708-.782a1.009,1.009,0,0,0-1.4,0,.991.991,0,0,0-.013,1.422c.472.492.957.977,1.456,1.449a.949.949,0,0,0,1.341.04q1.516-1.466,2.979-2.979a.906.906,0,0,0,.162-1.038.957.957,0,0,0-.856-.613,1.06,1.06,0,0,0-.869.371C172.557,289.919,171.877,290.6,171.142,291.348Z" transform="translate(-154.63 -265.438)" fill="#b0b0b0" />
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Secured Content
                  Management System</h5>
              </div></div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="34.506" height="34.517" viewBox="0 0 34.506 34.517">
                  <g id="Anti-DDOS_Security_" data-name="Anti-DDOS Security " transform="translate(0 0.003)">
                    <path id="Path_5220" data-name="Path 5220" d="M-.1,245.106a5.859,5.859,0,0,1,.762-.768c1.887-1.281,3.788-2.534,5.681-3.8a1.022,1.022,0,0,1,1.644.883c-.007.29,0,.58,0,.9h6.126V240.2h6.059v2.123h6.146v-.944a.994.994,0,0,1,.546-.937.962.962,0,0,1,1.051.067c2,1.334,4,2.655,5.991,4a1,1,0,0,1,0,1.732q-2.992,2.012-5.991,4a.969.969,0,0,1-1.078.054.99.99,0,0,1-.519-.917v-.95H20.213v2.008h.93a1,1,0,0,1,.944.539.982.982,0,0,1-.074,1.078c-1.328,1.988-2.649,3.983-3.983,5.958a2.971,2.971,0,0,1-.6.512h-.54a2.925,2.925,0,0,1-.58-.479c-1.348-1.995-2.682-4.01-4.017-6.018a.961.961,0,0,1-.061-1.051.989.989,0,0,1,.937-.539h.937v-1.995H7.994v.9a1.021,1.021,0,0,1-1.644.877c-1.894-1.267-3.794-2.521-5.675-3.8a5.133,5.133,0,0,1-.762-.768C-.1,245.47-.1,245.288-.1,245.106Z" transform="translate(0.1 -224.009)" fill="gray" />
                    <path id="Path_5221" data-name="Path 5221" d="M154.967-.024V3.251c0,.586-.007,1.166,0,1.752a.992.992,0,0,0,.984,1.031c1.368.013,2.743.013,4.111,0a.991.991,0,0,0,.97-1.078c.007-1.53,0-3.053,0-4.583v-.4h2.878a1.036,1.036,0,0,1,1.166,1.159v10.84c0,.357.007.721,0,1.078a1.008,1.008,0,0,1-1.051,1.065q-6.025.01-12.057,0a1,1,0,0,1-1.038-1.045q-.01-6.025,0-12.057a.981.981,0,0,1,.937-1.038C152.885-.045,153.909-.024,154.967-.024Z" transform="translate(-140.747 0.033)" fill="#b0b0b0" />
                    <path id="Path_5222" data-name="Path 5222" d="M241.3.3h1.981v4H241.3Z" transform="translate(-225.031 -0.278)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Anti-DDOS Security </h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="34.512" height="32.345" viewBox="0 0 34.512 32.345">
                  <g id="SQL_injection_prevention" data-name="SQL injection prevention" transform="translate(0 -0.005)">
                    <path id="Path_5234" data-name="Path 5234" d="M316.226,23.744c-.061.3-.115.613-.189.917a7.025,7.025,0,1,1,.129-2.487.933.933,0,0,0,.067.222C316.226,22.841,316.226,23.292,316.226,23.744Zm-8.094-.054c-.458-.472-.869-.917-1.3-1.334a1.006,1.006,0,1,0-1.429,1.415q.991,1.031,2.022,2.022a.969.969,0,0,0,1.422.007c1.361-1.341,2.7-2.7,4.05-4.044a.954.954,0,0,0,.263-.957,1,1,0,0,0-1.732-.411c-.876.856-1.732,1.725-2.6,2.6-.228.217-.433.437-.7.707Z" transform="translate(-281.721 -15.986)" fill="#b0b0b0" />
                    <path id="Path_5235" data-name="Path 5235" d="M18.443,136.083a9,9,0,0,0,5.2,7.2,8.9,8.9,0,0,0,8.849-.634c.007.115.013.2.013.283v14.254a3,3,0,0,1-2.285,3.033,3.366,3.366,0,0,1-.9.108q-13.051.01-26.109,0A3.043,3.043,0,0,1,.03,157.158q-.01-8.957,0-17.92a3.016,3.016,0,0,1,3.1-3.154q7.562-.02,15.13-.007C18.308,136.077,18.362,136.083,18.443,136.083Zm-6.477,12.974a4.043,4.043,0,0,0-5.392-6.012,3.911,3.911,0,0,0-1.462,2.642,4.067,4.067,0,0,0,1.166,3.377c-.081.067-.135.121-.2.168A4.643,4.643,0,0,0,4.191,152.3a21.918,21.918,0,0,0-.094,3.12.953.953,0,0,0,1.031.863q3.993.01,7.986,0a1.006,1.006,0,0,0,1.065-1.058c.007-.687.013-1.368-.007-2.056a4.936,4.936,0,0,0-1.57-3.565c-.2-.195-.42-.363-.636-.552Zm11.444,3.188h3.909a1.016,1.016,0,1,0,.007-2.015H19.474a1.035,1.035,0,0,0-1.045.647,1.013,1.013,0,0,0,1,1.375C20.754,152.252,22.082,152.245,23.41,152.245Zm0,4.044h3.943a1.013,1.013,0,1,0,.067-2.022c-1.227-.013-2.446-.007-3.673-.007h-4.28a1.014,1.014,0,1,0-.034,2.022C20.761,156.3,22.082,156.289,23.41,156.289Zm-3.033-9.1a1.008,1.008,0,1,0-1,1.011,1.008,1.008,0,0,0,1-1.011Zm3.026-1a1.008,1.008,0,1,0,1.018,1,1.008,1.008,0,0,0-1.018-1Zm4.044,0a1.008,1.008,0,1,0,1.018,1,1.008,1.008,0,0,0-1.018-1Z" transform="translate(-0.025 -127.982)" fill="gray" />
                    <path id="Path_5236" data-name="Path 5236" d="M90.051,350.191a4.486,4.486,0,0,1,.438-2.568,3.088,3.088,0,0,1,3.222-1.382,3.015,3.015,0,0,1,2.339,2.487c.054.479.054.957.081,1.462Z" transform="translate(-83.974 -323.926)" fill="gray" />
                    <path id="Path_5237" data-name="Path 5237" d="M109.137,258.122a2.019,2.019,0,1,1-2.015-2.022A2.04,2.04,0,0,1,109.137,258.122Z" transform="translate(-98.018 -239.917)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">SQL Injection
                  Prevention</h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="25.79" height="33.86" viewBox="0 0 25.79 33.86">
                  <g id="HTTPS_authentication" data-name="HTTPS authentication" transform="translate(0 -0.013)">
                    <path id="Path_9271" data-name="Path 9271" d="M110.25,264.585V246.753h25.79v17.832Zm12.875-3.773,1.983-9.919-1.944-.391-1.982,9.914Zm-3.248-6.376-1.389-1.391-2.606,2.606,2.589,2.58,1.372-1.383-1.249-1.223Zm7.874,1.218-1.323,1.223,1.382,1.378,2.578-2.584-2.6-2.612L126.45,254.4Z" transform="translate(-110.25 -230.712)" fill="gray" />
                    <path id="Path_9272" data-name="Path 9272" d="M182.366,76.86H178.4V72.822a3.967,3.967,0,1,0-7.934,0q0,2.826,0,5.652v.372H166.5V72.914a7.932,7.932,0,0,1,15.731-1.583,10.923,10.923,0,0,1,.133,1.61C182.378,74.238,182.366,75.536,182.366,76.86Z" transform="translate(-161.535 -64.825)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Https Authentication</h5>
              </div> </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SecurityofOur