import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Development Approach</span> of our</span>
              Coinbase Clone Script</h3>
            <p className="pharagraph head">Coinsclone always undergoes the following approaches for crafting a best-in-class coinbase clone script.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-left order1" >
            <img width="377px" height="326px" src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/requirement-gathering-planning.png" alt="Development Approach" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5>Requirement Gathering</h5>
            <p className="pharagraph" >Our experts will gather your business requirements and analyze the current crypto market to validate your project idea. Then, we give you the best solution for starting a crypto exchange like coinbase.
            </p>
            <br />
            <h5>Precise Planning </h5>
            <p className="pharagraph">Planning is the most important procedure that we follow in our development process. Our experts will plan the objectives and deadline of the project as per the client’s need.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5 >Designing</h5>
            <p className="pharagraph">Our designers will handle the front-end development and we design an interactive dashboard for trading without any difficulty. By using our advanced technologies, we create compelling designs.</p>
            <br />
            <h5 >Development</h5>
            <p  className="pharagraph">Our experienced development team will implement the best methods to develop a stunning user-to-admin crypto exchange like coinbase that is fully functional.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-right order1">
            <img width="425px" height="373px" src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/design-development.png" alt="Designing and Development" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-left order1" >
            <img width="370px" height="328px" src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/testing-deployment.png" alt="Testing and Deployment" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2 mb-0">
            <h5 >Testing</h5>
            <p  className="pharagraph">To check the quality of our product, our testing team will perform various types of testing and performance analysis.
            </p>
            <br />
            <h5 >Deployment</h5>
            <p  className="pharagraph mb-0">Our coinbase clone script software is ready to launch and you can begin your crypto exchange business to reap profits!
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach