import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from "gatsby-plugin-image"


class TopFeatures extends React.Component {

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Top Features</span> of </span>
                Our Coinbase Clone Script</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Instant Buy/Sell</h4>
                <p className="pharagraph">Our Coinbase app script helps you to buy/sell cryptocurrencies right away by eliminating the need to visit the order book.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/instant-buy-sell.png"
                  alt="Instant Buy/Sell"
                  width={520}
                />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/easily-send-and-receive.png"
                  alt="Easily Send and Receive"
                  width={520}
                />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Easily Send and Receive
                </h4>
                <p className="pharagraph">Transfer and Receive your cryptocurrencies to wherever and whomever in microseconds using our clone script.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-left" >
                <h4 className="heading-h4">Security Ready
                </h4>
                <p className="pharagraph">Our Coinbase app clone script takes careful measures to ensure that the majority of the users funds are securely stored offline.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/security-ready.png"
                  alt="Security Ready"
                  width={520}
                />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/wallet.png"
                  alt="Wallet"
                  width={520}
                />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Wallet
                </h4>
                <p className="pharagraph">Our coinbase script comes with the facility to generate a unique wallet address for every user who registers in the platform. Our clone script is compatible with all coins, alt-coins and tokens.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-left" >
                <h4 className="heading-h4">Intuitive User Interface
                </h4>
                <p className="pharagraph">With our coinbase script we provide a real time user and system interaction kind of interface which makes user experience easy and reduces their efforts to access the exchange.
                </p>
                <div className="text-left ">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/intuitive-user-interface.png"
                  alt="Intuitive User Interface"
                  width={520}
                />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures