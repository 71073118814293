import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

const OurAndroid = () =>  {

    return (
      <section className="android pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left leftpad">
          <h3 className="heading-h2"><span className="heading-h3">Our <span className="bluecolor">Android & iOS App</span></span>
              For Coinbase Clone App Development</h3>
            <p className="pharagraph head">Launch a user-friendly and eye-catching cryptocurrency trading mobile app like Coinbase
            </p>
          </div>

          <div className="row table-content mobilewhitebox orderflex">
            <div className="col-md-12 col-lg-7 order2 mb-0">
              <p className="pharagraph">In recent times, mobile-based crypto trading is getting popular in the crypto marketplace. To attract global crypto users and grab the attention of mobile-based crypto traders we are offering the best-in-class Coinbase Clone App development service along with our software. By using our service, you can launch a stunning crypto trading mobile app similar to the coinbase exchange app. Our Coinbase clone application fits well in both Android and IOS platforms.
              </p>
              <p className="pharagraph">Our ultra-modern coinbase clone mobile app will come with an eye-catching UI/UX that facilitates easy navigation for all modules on your coinbase clone exchange. Our premium Coinbase clone app enables your users to trade instantly with their smartphones. It comes with an amazing API that supports the user to conduct their trade without any technical interruptions.
              </p>
              <div className='mobile-hide'>
              <ButtonComponent />
              </div>
            </div>
            <div className="col-md-12 col-lg-5 mobileappicon text-center order1">
              <img width="530px" height="650px" src="https://coinsclone.mo.cloudinary.net/images/coinbase/coinbase-clone-app-development.png" alt="Coinbase Clone App Development" />
            </div>
          </div>

          <div className="inner-container">
            <div className="platform">
              <div className="t-black plat-dev">Platforms Developed</div>
              <div className="playstoreicon">
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/android.svg" alt="Android" />
                  <span>Android</span> </div>
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/apple.svg" alt="Apple" />
                  <span>ios</span> </div>
              </div>
            </div>
          </div>
          <div className='mobile-on'>
          <ButtonComponent />
          </div>
        </div>
      </section>
    )
}

export default OurAndroid