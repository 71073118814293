import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
    <section className="whatis mb-0 py-0">
      <div className='gray-bg transp'>
      <div className="container">
        <div className="shadoww">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className='bluecolor heading-h3'>What is</span> Coinbase Clone Script?</h2>
            </div>
          </div>
          <div className="text-center">
            <p className="pharagraph" >Coinbase Clone Script is a pre-designed user-to-admin cryptocurrency exchange software to start building your platform like Coinbase instantly with similar features. By using this script, you can make certain modifications to themes, designs, features, and more based on business requirements. We enable blockchain for security purposes. In addition to this, our Coinbase website script uses high-level security features. So your ready-made software inspired by Coinbase will be more secure and reliable.
            </p>
            <p className="pharagraph mb-0">Coinsclone is a one-stop solution and we offer all kinds of cryptocurrency exchange clone solutions with impeccable outcomes. Our premium Clone script inspired by Coinbase consists of an easy-to-handle interface that helps in creating a huge user engagement. The advanced UI used in our software enables your users to trade effectively without any technical hassles. Our script requires only minimum customizations and it’s bug-free, so you can launch your cryptocurrency exchange platform like Coinbase with Coinsclone in a hassle-free manner.
            </p>
          </div>
        </div>
      </div>
    </div>
    </section>
    )
  }
}

export default Whatis