import React ,{useEffect, useState} from 'react'
import { StaticImage } from 'gatsby-plugin-image'




const HowOur = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <section className="how-our mb-0">
      <div className="container">
        <h2 className="heading-h2 text-center"><span className="heading-h3 bluecolor">How our</span> Coinbase Clone Script work?</h2>
                { isDesktop && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/coinbase-workflow.webp" 
              alt="Working flow of our Coinbase clone software"
              width={1000} />
              )}
          { isMobile && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/coinbase-mobile.webp" 
              alt="Working flow of our Coinbase clone software"
              width={392} />
              )}
      </div>
    </section>
  )
}

export default HowOur