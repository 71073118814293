import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class CaseStudy extends React.Component {


  render() {

    return (
      <section className="banner  casestudy">  
        <div className='casestudy-bg'>
        <div className="container">
            <h2 className='heading-h2 text-center'>Our Recent <span className='bluecolor'>successful Work</span></h2>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <StaticImage 
                    src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/canapay-logo.webp"
                    alt="Canapay Logo"
                    width={300}
                    className='mb-4'
                />
                <ul>
                    <li>Canapay adopted our Coinbase Clone Script to build an OTC Exchange for users to buy and sell in bulk instantly.</li>
                    <li>The platform has tight security and a powerful User Interface with Credit or Debit card trading options.</li>
                    <li>The Canapay Learning Center helps users learn about Cryptocurrency and Blockchain technology</li>
                    <li>Canapay has become a popular Cryptocurrency exchange in Europe with <b>Ecstatic Trading Volume</b> and has been driving user adoption.</li>
                </ul>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/canapay.webp"
                alt="Canapay"
                width={780}
              />
            </div>
          </div>
          <div className='text-center'>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Get a free demo of our Coinbase clone script</a>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default CaseStudy