import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from "gatsby-plugin-image"

class Addon extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Add on Modules</span> of our</span>
              Coinbase Clone Script Software</h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                  Crypto Staking
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                  API Integration - Liquidity
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                  Gift Crypto
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                  API Documentation
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                  Referral System
                </li>
              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Crypto Staking
                      </h4>
                      <p className="pharagraph">Not only earn profits through buying & selling cryptocurrencies instead just stake them for a certain period of time and earn rewards for them using the staking feature we provide in our Coinbase app clone script.
                      </p>
                      <div className="text-left mt-4">
                        <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/crypto-staking.png"
                      alt="Crypto Staking"
                      width={305}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">API Integration - Liquidity
                      </h4>
                      <p className="pharagraph">Link your exchange with reliable API connection to obtain liquidity from any of the popular exchanges and offer your users an instant order execution.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/api-integration.png"
                      alt="API Integration - Liquidity"
                      width={460}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Gift Crypto
                      </h4>
                      <p className="pharagraph">Contribute your friends, family & closed ones with free crypto on their special occasions.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/gift-crypto.png"
                      alt="Gift Crypto"
                      width={270}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">API Documentation</h4>
                      <p className="pharagraph">We provide well structured site API documentation that can help in connecting your exchange with other platforms.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/api-documentation.png"
                      alt="API Documentation"
                      width={445}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Referral System
                      </h4>
                      <p className="pharagraph">With the referral system, both the company & users are benefited. As a company it increases their user base and for the users it helps them to earn rewards.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/referral-system.png"
                      alt="Referral System"
                      width={376}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon