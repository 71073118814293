import React from 'react'
import Slider from "react-slick";
import { StaticImage } from 'gatsby-plugin-image';
import ButtonComponent from '../ButtonComponent/ButtonComponent'



class Banner extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">Coinbase</span> Clone Script</h1>
                <p className="sub-heading">Create a top-notch user-to-admin cryptocurrency exchange platform like Coinbase with our customizable Coinbase clone script
                </p>
                <ul>
                  <li className="pharagraph">Our script is error-free, highly customizable, and secure
                  </li>
                  <li className="pharagraph">Create a modernistic user-to-admin exchange like Coinbase
                  </li>
                  <li className="pharagraph">Our premium Coinbase clone script has special trading features
                  </li>
                </ul>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
              <div className="screen">
              <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance-new/laptop-binance.png"
                  alt="Coinbase clone laptop front view"
                  width={876}
                  height={501}
                />
                <div className="outder">
                  <Slider {...settings}>
                  <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/coinbase-clone-script.png"
                      alt="coinbase clone script software slide1"
                      width={647}
                      height={403}
                    />
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/coinbase-clone.png"
                      alt="coinbase clone script software slide2"
                      width={647}
                      height={403}
                    />
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/coinbase.png"
                      alt="coinbase clone script software slide3"
                      width={647}
                      height={403}
                    />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner