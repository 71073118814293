import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"
import { StaticImage } from "gatsby-plugin-image"

class WhyShould extends React.Component {
  state = {
    modal: false,
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  render() {
    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2">
                <span className="heading-h3">
                  <span className="bluecolor">Why should</span> you Create a
                </span>
                Crypto Exchange Like Coinbase?
              </h2>
              <p className="pharagraph head">
              Developing a user-to-admin cryptocurrency exchange like Coinbase with the latest trading features will help you attract a wide range of users globally. Here we share some of the noteworthy reasons to create a crypto exchange similar to Coinbase.
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/why-should-you-like-coinbase-clone.png"
                  alt="Create Crypto Exchange like Coinbase"
                  width={500}
                />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph">
                Developing a user-to-admin cryptocurrency exchange like Coinbase with the latest trading features will help you attract a wide range of users globally. Here we share some of the noteworthy reasons to create a crypto exchange similar to Coinbase.
                </li>
                <li className="pharagraph">
                Coinbase is the most prominent user-to-admin crypto exchange platform that enables the user to buy, sell, or trade in cryptos securely. The user can trade any listed crypto to fiat currency or crypto to crypto using this medium.
                </li>
                <li className="pharagraph">When it comes to security, they are
                  highly encrypted with multiple layers of security walls.
                </li>
                <li className="pharagraph">
                Coinbase has a unique user interface and a variety of trading options that make the user make correct decisions as per their financial stand.
                </li>
                <li className="pharagraph">
                Coinbase has additional layers of protection that help users to trade without worrying about cyber theft.
                </li>
                <li className="pharagraph">
                By creating a crypto exchange like Coinbase, you can generate high revenue through multiple streams. It is one of the major reasons for launching a Bitcoin trading platform similar to Coinbase.
                </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould
